<template>
  <div>
    <v-card class="box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Subscriptions</span>
      </v-card-title>

      <preloader v-if="getting" />

      <v-card-text v-if="!getting && !subscriptions.length">
        <v-alert type="info" color="grey" text class="mt-5 mb-0">
          No subscription found.
        </v-alert>
      </v-card-text>

      <v-simple-table v-if="!getting && subscriptions.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Member</th>
              <th>Subscription</th>
              <th>Created</th>
              <th>Next Invoice Date</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="sub in confSubs" :key="sub.id" >
              <td class="py-3">
                <UserPhoto :id="sub.userData.id" photoSize="thumb" :size="35" class="mr-2" />
                <span>{{ sub.userData.firstName }} {{ sub.userData.lastName }}</span>
              </td>
              <td>{{ sub.priceData.description }}</td>
              <td class="caption grey--text">{{ sub.current_period_start.seconds * 1000 | formatDate }}</td>
              <td class="caption grey--text">
                <span v-if="sub.status == 'canceled'" class="mr-1">Active until</span>
                {{ sub.current_period_end.seconds * 1000 | formatDate }}
              </td>
              <td class="caption" :class="sub.status == 'active' ? 'primary--text' : 'red--text'">{{ sub.status }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions class="blue-grey lighten-5">
        <v-btn
          v-if="subscriptions.length >= limit"
          @click="getSubscriptions()"
          :loading="loadingMore"
          class="mr-2"
          color="secondary"
          depressed
          small
        >Load More</v-btn>

        <span class="caption">Showing {{ subscriptions.length }} items</span>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>

import db from '@/firebase/init'
import _orderBy from 'lodash/orderBy'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      subscriptions: [],
      getting: true,
      limit: 20,
      latVisible: null,
      loadingMore: false,
      users: [],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    confSubs: function () {
      return _orderBy(this.subscriptions, (sub) => {
        return sub.current_period_start.seconds
      }, 'desc')
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getSubscriptions() {
      db.collection('customers')
      .get()
      .then(cusSnapshots => {
        if (cusSnapshots.size) {
          cusSnapshots.forEach(customer => {
            // GET USER DATA
            db.collection('users')
            .doc(customer.id)
            .get()
            .then(userDoc => {
              if (userDoc.exists) {
                let userData = userDoc.data()
                userData.id = userDoc.id
                // GET CUSTOMER SUBSCRIPTIONS
                customer.ref.collection('subscriptions')
                .get()
                .then(subSnapshot => {
                  if (subSnapshot.size) {
                    subSnapshot.forEach(sub => {
                      let subData = this.$formatData(sub)
                      subData.userData = userData
                      // GET SUBSCRIPTION PRICE
                      subData.price.get()
                      .then(priceDoc => {
                        if (priceDoc.exists) {
                          let priceData = priceDoc.data()
                          subData.priceData = priceData
                          this.subscriptions.push(subData)
                          this.getting = false
                        }
                        else {
                          this.getting = false
                          console.log('price does not exist')
                        }
                      })
                    })
                  }
                  else {
                    this.getting = false
                    console.log('no subscriptions')
                  }
                })
              }
              else {
                this.getting = false
                console.log(`user ${customer.id} does not exist`)
              }
            })

          })
        }
        else {
          this.getting = false
          console.log('no cutomers')
        }
      })
      .catch(error => {
        console.log(error.message)
        this.$store.dispatch('showError', error.messsge)
        this.getting = false
      })
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getSubscriptions()
  }
}
</script>