<template>
  <div>
    <v-card class="box-shadow" outlined>
      <v-card-title :class="$classes.cardTitle">
        <span class="font-weight-bold">Recent Recipes</span>
        <v-btn :to="{ name: 'Recipes' }" color="primary" depressed small>View All</v-btn>
      </v-card-title>

      <v-card-text v-if="loading">
        <preloader />
      </v-card-text>

      <v-card-text v-if="!loading && !recipes.length">
        <v-alert type="info=">
          No recent recipes
        </v-alert>
      </v-card-text>

      <v-simple-table v-if="!loading && recipes.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th>Name</th>
              <th>Created</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="recipe in recipes" :key="recipe.id">
              <td> {{ recipe.name }} </td>
              <td class="grey--text caption">
                {{ recipe.createdAt | formatDate }}
              </td>
              <td class="text-right">
                <v-btn
                  @click="showRecipe(recipe)"
                  color="primary"
                  outlined
                  x-small
                >View Card</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <!-- RECIPE CARD -->
    <recipe-card
      v-if="recipe"
      :recipe="recipe"
      :show="showCard"
      @cancel="closeRecipeCard()"
    />
  </div>
</template>

<script>
import db from '@/firebase/init'
import RecipeCard from '../recipe/components/RecipeCard'

export default {
  /*------------------------------------------------------------------------------
  * DATA
  *----------------------------------------------------------------------------*/
  data() {
    return {
      recipes: [],
      loading: true,
      recipe: null,
      showCard: false,
    }
  },

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    RecipeCard
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    getRecentRecipes() {
      db.collection('recipes')
      .orderBy('createdAt', 'desc')
      .limit(5).get()
      .then(snapshot => {
        if (snapshot.size) {
          snapshot.forEach(recipe => {
            let data = this.$formatData(recipe)
            this.recipes.push(data)
          })

          this.loading = false
        }
        else {
          this.recipes = []
          this.loading = false
        }
      })
      .catch(error => {
        console.log(error.message)
      })
    },

    showRecipe(recipe) {
      this.recipe = recipe
      this.showCard = true
    },

    closeRecipeCard() {
      this.showCard = false
      this.recipe = null
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getRecentRecipes()
  }
}
</script>