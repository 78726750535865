<template>
  <div v-if="user">
    <template v-if="user.role == 'member'">

      <div v-if="!goal && !goalStatus.getting">
        <v-alert type="info" border="left">
          <span class="mr-2">Please create your goal first. </span>
          <v-btn :to="{ name: 'Goal' }" color="white info--text" small>Create Goal</v-btn>
        </v-alert>
      </div>
      <Meals v-else-if="goal && !goalStatus.getting" />
    </template>
    
    <template v-if="user.role == 'admin'">
      <admin-home />
    </template>
  </div>
</template>

<script>

import { mapState } from 'vuex'
import AdminHome from './admin/Home'
import Meals from '@/views/meals/Meals'

export default {
  name: 'Home',

  /*------------------------------------------------------------------------------
   * COMPONENTS
   *----------------------------------------------------------------------------*/
  components: {
    Meals,
    AdminHome,
    // Membership,
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      goal: state => state.goal.goal,
      goalStatus: state => state.goal.status,
    })
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  mounted() {
    if (this.user && this.user.role == 'member') this.$store.dispatch('goal/getGoal')
    this.$store.dispatch('subscriptions/getSubscriptions')
  },
}

</script>
